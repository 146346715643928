const links = [
    {
        label: 'Github',
        url: 'https://github.com/ReneSena'
    },
    {
        label: 'Linkedin',
        url: 'https://br.linkedin.com/in/rene-sena'
    },
    {
        label: 'Twitter',
        url: 'https://twitter.com/sleeperU_U'
    }
    /*{
        label: 'Instagram',
        url: 'https://instagram.com/rene_sena'
    }*/
];

export default links;